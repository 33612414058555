import { structureType } from '~/constants'
import type { inputParameterEnum, inputParameterWithoutLora } from '~/constants/input-parameter'
import { exteriorStructures, interiorStructures } from '~/constants/input-parameter'

export const isInterior = (structure: string) =>
  structure === structureType.interior.en

export const isInputParameterWithoutLora = (key: inputParameterEnum) => {
  if (key in exteriorStructures || key in interiorStructures) {
    return true
  }
  return false
}

export const getExteriorStructures = (key: inputParameterEnum) => {
  if (isInputParameterWithoutLora(key)) {
    return exteriorStructures[key as inputParameterWithoutLora]
  }
}

export const getInteriorStructures = (key: inputParameterEnum) => {
  if (isInputParameterWithoutLora(key)) {
    return interiorStructures[key as inputParameterWithoutLora]
  }
}
